.about {
  background-color:$grey_light;
  padding-top: 90px;
  width: 100%;
}
.about__content {
  @extend %clearfix;
 // padding-bottom: 60px;

}
.about__left {
  float: left;
  width: 43%;

  @include phone(){

  width: 96%;
  margin-bottom: 60px;
  }

}
.about__right {
  float: left;
  width: 57%;

  @include phone(){
    width: 96%;
  }

}
h2.about__more {
  height: 60px;
  width: 100%;
  text-align: center;
  line-height: 0.1em;
  //margin: 10px 0 20px;
  border-top: solid  $orange 5px;
}
.about__more_link  {
  font-size: 1.125rem;
  color: $orange;
  background-color:$grey_light;
  padding:0 60px;
  &:hover {
    color: $orange_hover;
    cursor: pointer;
  }

}


.about__desc {
 font-family: 'Arial', sans-serif;
 font-size: 0.9375rem;
 color: $grey_dark ;
 line-height: 25px;
 margin-bottom: 27px;
}

.about__catalog-btn{
  display: none;



}
.about__map {
  & img {
    width: 100%;
  }
}
.about__title {
  display: inline-block;
  font-size: 2.25rem;
  font-family: din_bold;
  font-weight: 600;
  color: $grey_dark;
  text-transform: uppercase;
  margin-bottom: 30px;
  &.hover {
    text-decoration: underline;
  }
}



.mapin{
  position:relative;
  padding-bottom:75%; /*		450/600		*/
  height:0;
  overflow:hidden;
}
.mapin iframe{
  position:absolute; top:0; left:0; width:100%; height:100%;
}
/*на странице О компании*/
.pages-about {
  .about {
    background-color: white;
    padding-top: 70px;
    margin-bottom: 80px;
  }
  .about__more {
    display: none;
  }
  .about__catalog-btn {
    display: block;
    @include phone {
      display: none;
    }
  }
  .about__catalog-btn_mobile {
    display: none;
    @include phone {
      display: block;
      margin: 0 15%;
    }
  }
  .about__right {
    margin-bottom: 30px;
  }
}

