.footer {
  height: $footer-height;
  margin-top: - $footer-height+50px;
  background-color: $footer-background;
  width:100%;
  /*min-width: $container-width + 20px;*/
  @include phone() {
    height: $footer-height*2+100;
  }


}
.footer__left {
  float: left;
  width: 34%;
  height: $footer-height;

  @include phone() {
    width: 100%;
  }
}
.footer__right {
  float: left;
  width: 66%;
  height: $footer-height;
  position: relative;
  @include phone() {
    width: 100%;
    margin: 0 auto;

  }

}
.map, .footer__map {
  width: 100%;
  height:  $footer-height;

}
.footer__left-inside {
  padding-left: 52%;
  height: $footer-height;
  position: relative;
  @include phone() {
    padding-left: 45%;
  }

}
.footer__logo{
  margin-top: 25px;
  margin-bottom: 44px;
}
.footer__menu {

  & .menu {
    padding-top: 0px;
    font-size: 0.875rem;
  }
  & .menu__item {
    margin-bottom: 24px;
  }
  & .menu__link {
    padding-left:2px;
  }
  & .menu__link:hover {
    border-left: none;

  }
  & .menu__item_red {
    display: none;

  }

}
.footer__copyright {
   width: 48%;
   display: inline-block;
   position: absolute;
   bottom: 40px;
   left: 52%;
  @include phone {
    display: none;
  }
}
.footer__copyright_phone {
  display: none;
  @include phone() {
    float: left;
    width: 100%;
    background-color: $footer-background;
    padding: 20px 26px 26px 15px;
    width: 100%;
    display: block;
    position: relative;

  }

}
.footer__copyright__text {
  font-family: 'Arial', sans-serif;
  font-size: 0.8125rem;
  color:$grey ;
}
.social-network {
  position: absolute;
  fill: $grey_light;
  width: 26px;
  height: 26px;
  display: inline-block;
  text-align: right;
  right: 20px;
  bottom: 0px;
  @include phone() {
    right: 26px;
    bottom: 20px;
  }

}

svg.triangle
{
  width: 15px;
  height: 13px;
  position: absolute;
  left: 0px;
  top: -13px;
  fill:$grey;
  //transform: rotate(180deg);

}

.footer__contacts-wrap {
  position: absolute;
  top:50%;
  left: -16px;
  transform: translateY(-50%);
  z-index: 25;
  display: inline-block;
  padding: 31px 37px 31px 41px;
  background-color: $grey_light;
 // margin-top: -46px;

}
.footer__contacts {
  background-color: $grey_light;


}


.contacts__phone {
  position: relative;
  font-family: din_light;
  font-size: 1.5rem;
  color: $grey_dark;
  margin-bottom: 19px;

}
.contacts__phone-icon {
  position: absolute;
  top:50%;
  left:0px;
  margin-top: -12px;
  display: inline-block;
  width: 24px;
  height: 24px;

  fill: $orange;
}
.contacts__phone-text {
  padding-left: 40px;
  font-family: din_light;
  font-size: 1.5rem;
  color: $grey_dark;
}
.contacts__email {
  font-family: 'Arial', sans-serif;
  font-size: 0.875rem;
  color: $orange;

  padding-left: 45px;
  margin-bottom: 19px;
  & span {
    border-bottom: dotted 1px $orange;
  }
}
.contacts__address {
  font-family: 'Arial', sans-serif;
  font-size: 0.875rem;
  color:$grey;
  line-height: 1.42;
  padding-left: 45px;

}



 [class*="ymaps-2"][class*="-ground-pane"] {
   filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
   -webkit-filter: grayscale(100%) brightness(30%)  !important;
 }

