.count-form {
  width: 54%;
  display: block;
  margin: 0 auto;
  position: relative;

  @include phone {
    width: 94%;
  }
}
.count-form__title {

  font-size: 2.25rem;
  font-family: din_bold;
  font-weight: 600;
  color: $grey_dark;
  text-transform: uppercase;
  margin-bottom: 35px;
  text-align: center;
  @include phone {
   text-align: center;
  }

}
.count-form__item {
  margin-bottom: 16px;
  width: 100%;
}
.count-form__inputs {
  padding-left: 5px;
  width: 96%;
  margin: 0 auto;
  font-family: din_regular;
  font-size: 1.25rem;
  color: $grey;

  & input, textarea {
    width: 100%;
    background-color: $grey_light;
    padding: 10px 0px 10px 20px;
    border: solid #e0dfdd 1px;
    outline: 0;
    outline-offset: 0;

  }
   & textarea {

      overflow: auto;
      resize: none;
      height: 150px;
      padding: 10px 0px 0px 20px;


    }
}
.count-form__btn-wrapper {
  width: 224px;
  display: block;
  margin: 27px auto;


}
.count-form__btn {
  display: inline-block;
  background-color: $orange;
  font-family: din_bold;
  color: $grey_light;
  font-size: 1.125rem;
  font-size: 18px;
  text-transform: uppercase;
  padding: 20px 30px;

  &:hover {
    background-color: $orange_hover;
    cursor: pointer;
  }

}
.count-form__inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;

}
.count-form__inputfile + label {
  width: 100%;
  background-color: $grey_light;
  padding: 10px 0px 10px 68px;
  border: solid $grey_border 1px;
  outline: 0;
  outline-offset: 0;
  display: inline-block;
  cursor: pointer; /* "hand" cursor */
}
.count-form__inputfile:focus + label,
.count-form__inputfile + label:hover {
  /*background-color: white;*/

}
.count-form__inputfile:focus + label {

}
.count-form__inputfile + label * {
  pointer-events: none;
}
.inputfile-wrap {
  position: relative;
}
.clip {
  position: absolute;
  vertical-align: middle;
  width: 35px;
  height: 26px;
  top: 50%;
  left: 18px;
  margin-top: -15px;
  fill: $orange;
}

.count-form__comment {
  font-family: Arial;
  color: $grey;
  font-size: 0.81rem;
  line-height: 1.3;
 & i {
   color: $orange;
   padding-right: 3px;
 }
}
