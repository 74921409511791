.product-filter {
  margin-bottom:30px;
  font-size:1.25rem;
  color: $grey;
  padding-top: 20px;
}

.product-filter__available {
  display: inline-block;
  @include phone {
    width: 30%;
  }

}
.product-sort {
  display: inline-block;
  position: relative;
  margin-left:28px;
  @include phone {
    width: 61%;
  }
}
.arrow-down__icon2 {
  width: 20px;
  height: 20px;
  fill: $grey;
  &:hover {
    fill: $grey_dark_hover;
    cursor: pointer;
  }

  position: absolute;
  top: 50%;
  transform: translateY(-50%)  rotate(90deg);;
  right: 10px;
}
.product-sort-wrap {

  width: 340px;
  height: 44px;
  background-color: $grey_light;
  position: relative;
  padding: 10px 20px;
  @include phone {
    width: 100%;
  }
}

.product-sort__link {


}

.product-sort__items {
  display: none;
  z-index: 5;
  position: absolute;
  top: -12px;
  left: 0px;
  background-color: $white;
  border: solid 1px $grey_border;
  white-space: nowrap;

  box-shadow: 0 2px 6px #010101; /* Параметры тени */
   @include phone{
     right: 0px;
     left: auto;
   }

}
.product-sort__item {
  display: block;
  padding: 15px 20px;
  &:hover{
    background-color: $grey_light;
  }
  &:first-child {
    background-color: $grey_light;
  }
}

.product-list__items {
  font-family: Arial;
  color: $grey_dark;
  margin-bottom:30px;
}

.product-list__item-link {
  font-size:1.25rem;
  color: $grey;
}
.product-list__item  {


  border: solid 1px $grey_border;
  padding-top: 18px;
  padding-left: 26px;
  margin-bottom:3px;
  &:hover {
    box-shadow: 0 2px 6px #010101; /* Параметры тени */
  }
}
.product-list__item-link {
  width: 100%;
}
.product-list__item-left {
  display: inline-block;
  width: 73%;
}
.product-list__item-right{
  display: inline-block;
  width: 24%;
}

.product-list__item-group {

  font-weight: bold;
  font-size: 1.25rem;
  margin-bottom: 10px;

}
.product-list__item-name {

  font-weight: bold;
  font-size: 0.9375rem;
  margin-bottom: 10px;

}
.product-list__item-info-title {
  font-size: 0.9375rem;
  margin-bottom: 16px;
}

.product-list__item-info {
  color: $grey;
  font-size: 0.875rem;
  margin-bottom: 16px;

}

.product-list__item-code-v , .product-list__item-year-v, .product-list__item-gost-v {
  font-size: 1rem;
  color: $grey_dark;
  margin-left: 5px;
  margin-right: 35px;
}
.product-list__item-code-v  {
  color: $orange;
}
/*right*/
.product-list__item-right {
  text-align: right;
  margin-right: 13px;
}
.product-list__item-anable {

  font-size: 0.9375rem;
  color: $grey;
  margin-bottom: 17px;
}
.product-list__item-price {
  color: $grey_dark;
  font-weight: bold;
  font-size:1.25rem;
  margin-bottom: 23px;
}


.product-list__item-basket {
 position: relative;

}
.basket-unit {
  font-size: 1.125rem;
  font-weight:bold;
  position: absolute;
  top: 50%;
  margin-top: -15px;
  left:0px;
}


.basket-counts {
  display: inline-block;
  position: absolute;
  right: 60px;
  font-family: din_regular;
  font-size: 1.5rem;
  background-color: #eeece9;
  padding: 4px 16px;
  text-align: center;
}
.basket-number, .basket-plus {

}
.basket-number {
  display: inline-block;
  margin: 0 14px;
}
.basket-icon-wrapp {
  display: inline-block;
  position: relative;
  width: 55px;
  height: 41px;
  background-color: $orange;
  margin-left: 5px;
  &:hover {
    background-color: $orange_hover;

  }

}
.basket-icon22 {
  width: 33px;
  height: 33px;

  position: absolute;
  top: 50%;
  margin-top: -16.5px;
  left:52%;
  margin-left:-16.5px;
  fill: $white;
}

.filter-catefory__name {

  font-family:din_light;
  color: $grey_dark;
  font-size:1.5rem;
  margin-bottom:20px;
}

.product-list__navigator {

  display: block;
  text-align: center;
}
.navigator__result {

  color: $orange;
  display: inline-block;
}

/* product card*/


.product {
  color: $grey_dark;
  font-weight: bold;
  @extend %clearfix;
}
.product-left {
  float: left;
  width: 60%;
  @include phone {

    display: block;
    width: 100%;
  }
}
.product-right {
  float: right;
  width: 40%;
  border: solid 1px $grey_border;
  padding: 0 4px;
  padding-top:20px;
  @include phone {
    display: block;
    width: 100%;
  }


}
.product-group {

  font-size:1.25rem;
  margin-bottom: 13px;

}
.product-name {
  font-size:0.9375rem;
  margin-bottom: 10px;
}
.product-info-title {
  ont-size:0.9375rem;
  font-weight:normal;
  margin-bottom: 48px;
}

.product-info{
  color: $grey;
  font-size: 0.9375rem;
  font-weight:normal;
}

.product-info-row {
  margin-bottom:17px;
}
.product-code-v , .product-year-v, .product-gost-v {
  font-size: 1rem;
  color: $grey_dark;
  margin-left: 5px;
  margin-right: 35px;
}

.product-code-v {
  color: $orange;
}
.product-right-text {
  text-align: right;
  margin-right: 10px;
}
.product-anable {
  color: $grey;
  font-size:0.875rem;
  font-weight:normal;
  margin-bottom:14px;
}
.product-price {

  font-size:1.25rem;
  margin-bottom:20px;
}
.product-basket {
  margin-bottom:16px;
  .basket-unit {
    display: inline-block;
    position: static;
  }
  .basket-counts {
    display: inline-block;
    position: static;
    margin-left:15px;
  }

}

.product-basket-order {
  margin-bottom:5px;
}
.product-basket-add {
  position: relative;
  margin-bottom:5px;

}
.product-basket-add__icon {
  width: 36px;
  height: 30px;
  position: absolute;
  top:50%;
  margin-top:-18px;
  right: 10px;


}

.button_orange_icon {
  display: inline-block;
  background-color: $orange;
  font-family: din_bold;
  color: $grey_light;
  font-size: 1.125rem;
  text-transform: uppercase;
  padding: 20px 30px;

  &:hover {
    background-color: $orange_hover;
    cursor: pointer;
  }

}
.product-list__items {
  @include phone {
    display: none;
  }
}

.product-list__items-mob {

  display: none;
  @include phone {
    display: block;
  }

   font-family: Arial;
   color: $grey_dark;
   margin-bottom:30px;

  .product-list__item-left {
    display: inline-block;
    width: 58%;
    vertical-align: top;
  }
  .product-list__item-right {
    display: inline-block;
    width: 38%;
    vertical-align: top;
  }

  .product-list__item-code , .product-list__item-year, .product-list__item-gost {
    display: inline-block;
  }
  .product-list__item-info__row {
    margin-bottom:15px;
  }
  .basket-unit {
    left: -18px;

  }
}