// adjustment vars
$footer-height: 440px;
$white: #ffffff;
$orange:#e64814;
$orange_hover: #ff653b;
$footer-background:  #3c3c3b;
$transition: 0.5s;
$grey_dark : #3c3c3b;
$grey_dark_hover :#5e5e5d;
$grey_light: #eeece9;
$grey_hover :#9b9b9b;
$grey: #6f6f6e;
$grey_border:#e0dfdd;
$bardo:#f45a40;

// adjustment styles
body, html {
	width: 100%;
	/*height: 100%;*/
	font-size: 16px;
	color: $grey;
	line-height: 1.42;

	//margin: 0;
    //	padding:0;
	@include desktop {
		font-size: 16px;
		//$container-width: 970px;

	}
	@include laptop {
		font-size: 16px;
		//$container-width: 750px;
	}
	@include tablets{

		font-size: 16px;
		//$container-width: 720px;

	}
	@include phone{
		font-size: 16px;

	}
}

body {
	font-size: 0.75em;
	font-family: 'Arial', sans-serif;
	color: $grey_dark;
	line-height: 1.42;
	-webkit-font-smoothing: antialiased;
	background: $white;
}

.wrapper {
	min-height: 100%;
	overflow: hidden;
	position: relative;
	margin:0 auto;
	/*@include desktop {
		width: 990px;

	}
	@include laptop {
		width: 770px;
	}
	@include tablets {
		width: 740px;
	}
	@include phone {
		width: 100%;

	}*/
}

.wrapper:after {
	content: "";
	display: block;
	height: $footer-height;
	overflow: hidden;



}



.container_extended {
/*	width: $container-width;*/
	width: 1710px;
	margin: 0 auto;
	@extend %clearfix;
	//	padding:0;
	@include desktop {
		width: 970px;
		min-width: 990px;

	}
	@include laptop {
		width: 750px;
		min-width: 770px;
	}
	@include tablets {
		width: 720px;
		min-width: 740px;

	}
	@include phone {
		width: 95%;
		min-width: 95%;

	}
}

li {
	list-style: none;
}

ul {
	padding: 0;
	margin: 0;
}

footer {
	height: $footer-height;
	width: 100%;
	margin-top: -$footer-height;
	position: relative;
/*	min-width: $container-width + 20px; */
}

h1, h2, h3, h4, h5, h6 {
	margin-top: 0;
	margin-bottom: 0;
	font-weight: normal;
}

* {
	box-sizing: border-box;
}
a {
	text-decoration: none;
}


html, body{
	//width: 100%;
	//min-height: 100%;
	height: 100%;
	overflow: hidden;


}
body {

	margin: 0;
	padding:0;
/*
	height: 100vh;
	overflow-x: hidden;
	overflow-y: auto;
	-webkit-perspective: 1px;
	-moz-perspective: 1px;
	perspective: 1px;
*/

}

.container {
	width: 1110px;
	margin: 0 auto;

	//@extend %clearfix;
	//	padding:0;
	@include desktop
	{
		width: 970px;
		min-width: 990px;
	}
	@include laptop
	{
		width: 750px;
		min-width: 770px;
	}
	@include tablets
	{
		width: 720px;
		min-width: 740px;
	}
	@include phone
	{
		width: 92%;
		min-width: 92%;

	   /*min-width: 95%;*/
	}
}

.parallax {
	height: 100%;
	height: 100vh;

	overflow-x: hidden;
	overflow-y: auto;
	-webkit-perspective: 1px;
	perspective: 1px;
}

.parallax__layer {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

.parallax__layer--base {

}

.parallax__layer--back {
	-webkit-transform: translateZ(-1px);
	transform: translateZ(-1px);
}
