.pages-catalog {
  @extend %clearfix;
}
.pages-catalog-list {
  overflow: auto;
  min-width: 500px;

}
.pages-catalog__left {
  float:left;
  width: 74%;
  @include  phone{
    display: block;
    width: 100%;


  }

}

.pages-catalog__right {
  float:right;
  width: 23%;
  margin-left: 30px;
  @include phone {
    display: block;
    width: 100%;
  }
}
.catalog {
  margin-bottom: 30px;
  @include phone {
    display: none;
  }

}

.catalog-filter {
  display: none;
}

.pages-catalog-list {

  .catalog-filter {
    display: block;
  }
  .red_line-margin {
    margin-bottom: 17px;
    margin-top:0px;
  }
  .filter-catefory {
    margin: 0 17px;
  }
  .filter-catefory__byname {
    margin-bottom: 17px;
  }
  .filter-catefory__bygost {
    margin-bottom: 15px;
  }
  .filter-catefory__clear {
    display: block;
    color: $grey;
    font-size:0.875rem;
    text-align: right;
    margin-bottom:17px;

  }
}


.catalog-filter-cat {
  width: 100%;
  border-top: dashed $orange 1px;
  border-bottom: dashed $orange 1px;
  font-family: din_light;
  font-size: 1.5rem;
  text-transform: uppercase;
  margin-bottom: 40px;
  text-align: center;
  margin-left: 0px;
  position: relative;
  @include phone(){
    display: inline-block;
    text-align: left;
    padding: 0px 17px;
  }
}
.catalog-filter-cat__label {
  color: $orange;
  margin: 20px auto;

}

.catalog-filter-cat__arrow {
  display: none;
  @include phone() {
    display: inline-block;
    text-align: right;
  }
}
.arrow-down__icon {
  width: 20px;
  height: 20px;
  fill: $grey;
  &:hover {
    fill: $grey_border;
    cursor: pointer;
  }

  position: absolute;
  top: 50%;
  transform: translateY(-50%)  rotate(90deg);;
  right: 17px;
}

.catalog__item {
  width: 160px;
  height: 160px;
  position: relative;

  background-color: $grey_light;
  display: inline-block;
  font-size: 0.875rem;
  color: $grey_dark;

  border-bottom: dashed $grey_border 1px;
  margin-bottom: -1px;
  text-align: center;
  &:hover  {
    background: $white;
    border: solid 1px $grey_light;

  }
}
.catalog__item-link {
  font-size: 0.875rem;
  color: $grey_dark;
}
.catalog__item-icon {


}
.catalog__icon {
  width: 70px;
  height: 73px;
  fill:$grey_dark;
  position: absolute;
  top: 27px;
  left: 50%;
  margin-left: -35px;


}
.catalog__item-title {
  width: 100%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 15px;
  line-height: 1.1;
}



.catalog-list {
  @include phone{
    display: none;

  }
}
.catalog-list__items {

  margin-bottom:25px;



  .catalog-list__item {


    position: relative;
    height: 36px;

    &:hover {
      background-color: $grey_light;
      cursor: pointer;
    }

  }
  .catalog-list__item-title_high {
    height: 50px;
  }

  .catalog__icon {
    width: 28px;
    height: 28px;
    fill:$grey_dark;
    position: absolute;
    top: 50%;
    margin-top: -14px;
    left: 46px;

  }
  .catalog-list__item-title{

    font-size: 0.875rem;
    color: $grey_dark;
    padding: 10px 0px;
    position: absolute;
    top:50%;
    transform: translateY(-50%);
    left:61px;
    line-height: 1.8;
  }
  .catalog-list__item-link {

  }




}
.catalog-red_line-margin  {
  margin-bottom:25px;

}
.pages-catalog__promo {
  margin-bottom: 68px;
  .catalog_promo__img{
    width: 100%;
  }
}


.pages-catalog-product {
  overflow: auto;
  .catalog-filter {
    @include phone {
      display: none;
    }
  }
}