.basket-list {
  //margin-bottom:30px;
  font-size:1.25rem;
  color: $grey;
}

.basket__title {
  width: 100%;
  display: inline-block;
  text-align: center;
  font-family: din_bold;
  font-size:2.25rem;
  color: $grey_dark;
  margin-bottom: 30px;
}

.basket-list__items {
  font-family: Arial;
  color: $grey_dark;
  margin-bottom:30px;
}

.basket-list {
  .product-list__item {
    position: relative;
  }
  .product-list__item-left {
    display: inline-block;
    width: 62%;
    @include phone {
      display: block;
      width: 100%;
      margin-bottom:30px;
    }
  }
  .product-list__item-right{
    display: inline-block;
    width: 37%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0%;
    padding-right: 15px;

    @include phone {
      position: static;
      display: block;
      width: 100%;

    }

  }
  .basket-unit {
    display: inline-block;
    position: static;

  }

  .basket-counts {
    width: 94px;
    position: static;
    display: inline-block;
    font-family: din_regular;
    font-size: 1.5rem;
    background-color: #eeece9;
    padding: 4px 16px;
    text-align: center;
    margin-left:15px;
  }
  .product-list__item-price {
    display: inline-block;
    margin-bottom:0px;
    margin-left:100px;
  }

}
.basket__red_line-margin {
  margin: 20px 0px;
}
.basket__comment {

  font-family:din_light;
  font-size:1.5rem;
  color: $grey_dark;
  margin-bottom: 20px;
  text-align: right;
}
.basket__buttom {
  @extend %clearfix;
  position: relative;
}
.basket__buttom-back {
 float: left;
}
.basket__buttom-btn {
  float: right;
}
.basket__buttom-back__title {
  font-size:1.125rem;
   clear: $grey;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left:45px;
}

.basket__buttom-back {
 vertical-align: middle;

}

.arrow-left-orange {
  width: 31px;
  height: 31px;
  fill: $orange;
  &:hover {
    fill: $orange_hover;
    cursor: pointer;
  }
  transform: rotate(180deg);
  position: absolute;
  top: 50%;
  margin-top:-15.5px;
}

.basket-item__close {
  position: absolute;
  top:0px;
  right: 5px;
  &:hover {
    cursor: pointer;
    .close-icon {
      fill: $grey_dark_hover;
    }
  }
}

.basket-close-icon {
  width: 12px;
  height: 9px;
  fill: $grey_dark;

}
.basket__buttom-catalog-wrap {
  width: 100%;
  display: inline-block;
  text-align: center ;
  margin-bottom:60px;

}

.basket-list__items {
  @include phone {
    display: none;
  }
}
.basket-list__items-mob {

  display: none;
  @include phone {
    display: block;
  }
  .product-list__item-info {
    margin-bottom:70px;
  }
  .basket-wrap {
    display: block;
    width: 100%;
    text-align: center;
    margin-bottom:15px;

  }
  .product-list__item-price {
    display: block;
    width: 100%;
    text-align: center;
    margin-left:0px;
  }

}