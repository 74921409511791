.subscribe {
 width: 563px;
 margin: 0 auto;
 text-align: center;
  @include  phone{
    width: 100%;
  }



}
.subscribe__title {

  margin-bottom: 37px;

}
.subscribe__text{
  font-size: 1.41rem;
  color: $white;
  font-family: 'Roboto Condensed Bold', sans-serif;

}
.subscribe__input {
  color:$grey;
  text-align: left;
  font-size: 1.25rem;
  padding: 10px 0px 10px 20px;
  margin-bottom: 32px;
  background-color: $grey_light;
  width: 100%;
  border: solid #e0dfdd 1px;
  outline: 0;
  outline-offset: 0;


}
.subscribe__button {
  display: inline-block;
  text-align: center;
  background-color: $orange;
  font-family: din_bold;
  color: $grey_light;
  font-size: 1.125rem;
  text-transform: uppercase;
  padding: 15px 50px;

  &:hover {
    background-color: $orange_hover;
    cursor: pointer;
  }

  margin-bottom: 28px;

}

.subscribe__comment{
  text-align: left;
  font-family: Arial;
  color: $grey;
  font-size: 0.81rem;
  line-height: 1.3;
  & i {
    color: $orange;
    padding-right: 3px;
  }
}
