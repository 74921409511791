@mixin centered($axis: 'both') {
    position: absolute;

    @if $axis == 'both' {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    @if $axis == 'x' {
      left: 50%;
      transform: translateX(-50%);
    }

    @if $axis == 'y' {
      top: 50%;
      transform: translateY(-50%);
    }
}

%clearfix {
  &:after {
    content: "";
    display: block;
    clear: both;
  }
}

@mixin box-shadow($params){
  box-shadow: $params;
  -webkit-box-shadow : $params;
  -moz-box-shadow: $params;
}

@mixin inlineblock($valign: top) {
  display: inline-block;
  vertical-align: $valign;
}


@mixin desktop() {
  @media screen and (max-width: 1200px) {
    @content
  }

}

@mixin laptop() {
  @media screen and (max-width: 992px) {
    @content
  }

}
@mixin tablets() {
  @media screen and (max-width: 768px) {
    @content
  }
}
@mixin phone() {
  @media screen and (max-width: 530px) {
    @content
  }

}
@mixin album(){
  @media screen and (orientation:landscape) and (max-width:768px){
    @content;
  }
}


@mixin desktop_only() {
  @media only screen and (min-width: 992px) {
    @content
  }
}

@mixin laptop_only() {
  @media only screen and (min-width: 768px) and (max-width: 990px){
   @content
  }
}
@mixin tablets_only() {
  @media only screen and (min-width: 485px) and (max-width: 767px) {
    @content
  }
}
@mixin phone_only() {
  @media only screen and (max-width: 480px) {
    @content
  }

}

