.content {


}
.sidebar {
  float:left;
  width: 23.4%;
}
.page-content {
  float: left;
  width:76.6%;
  padding-left: 20px;


}

.content__title {
  margin-bottom: 23px;
}