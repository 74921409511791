.contact-form {
  width: 54%;
  min-width: 480px;
  display: block;
  margin: 0 auto;
  position: relative;
  font-family: din_regular;
  font-size: 1.25rem;

  @include phone {
    width: 94%;
  }
}
.contact-form__title {

  font-size: 2.25rem;
  font-family: din_bold;
  font-weight: 600;
  color: $grey_dark;
  text-transform: uppercase;
  margin-bottom: 35px;
  text-align: center;
  @include phone {
   text-align: center;
  }

}
.contact-form__item {
  margin-bottom: 16px;
  width: 100%;
}
.contact-form__inputs {
  padding-left: 5px;
  width: 96%;
  margin: 0 auto;
  font-family: din_regular;
  font-size: 1.25rem;
  color: $grey;

  & input, textarea {
    width: 100%;
    background-color: $grey_light;
    padding: 10px 0px 10px 20px;
      border: solid #e0dfdd 1px;
    outline: 0;
    outline-offset: 0;

  }
   & textarea {

      overflow: auto;
      resize: none;
      height: 150px;
      padding: 10px 0px 0px 20px;


    }
}
.contact-form__btn-wrapper {
  width: 224px;
  display: block;
  margin: 27px auto;


}
.contact-form__btn {
  display: inline-block;
  background-color: $orange;
  font-family: din_bold;
  color: $grey_light;
  font-size: 1.125rem;
  font-size: 18px;
  text-transform: uppercase;
  padding: 20px 30px;

  &:hover {
    background-color: $orange_hover;
    cursor: pointer;
  }

}
.contact-form__inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;

}
.contact-form__inputfile + label {
  width: 100%;
  background-color: $grey_light;
  padding: 10px 0px 10px 20px;
  border: solid #e0dfdd 1px;
  outline: 0;
  outline-offset: 0;
  display: inline-block;
  cursor: pointer; /* "hand" cursor */
}
.contact-form__inputfile:focus + label,
.contact-form__inputfile + label:hover {
  /*background-color: white;*/

}
.contact-form__inputfile:focus + label {

}
.contact-form__inputfile + label * {
  pointer-events: none;
}
.inputfile-wrap {
  position: relative;
}
.calendar {
  position: absolute;
  vertical-align: middle;
  width: 35px;
  height: 35px;
  top: 50%;
  right: 9px;
  margin-top: -17.5px;
  fill: $orange;
}

.contact-form__comment {
  font-family: Arial;
  color: $grey;
  font-size: 0.81rem;
  line-height: 1.3;
 & i {
   color: $orange;
   padding-right: 3px;
 }
}

/*check box for selecting time*/
.contact-form__time-label {


}
.contact-form__time-time {
  display: inline-block;
  text-align: right;
  width: 74%;

}
.checkbox_container_right { margin-left: 32px}
.checkbox_container {

  position: relative;

/*  padding-left: 39px;
  margin-left: 28px;
*/
  padding-left: 32px;
 // margin-left: 10px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

}

/* Hide the browser's default checkbox */
.checkbox_container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;

}

/* Create a custom checkbox */
.checkmark {
  width: 15px;
  height: 15px;
  background-color: $white;
  border: solid 1px $grey_dark;
  margin:4px;


}
.checkmark__outer {
  position: absolute;
  margin-top: -12.5px;
  top: 50%;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: $grey_light ;
  border: solid 1px #e0dfdd;
  margin-right: 10px;


}


/* On mouse-over, add a grey background color */
.checkbox_container:hover input ~ .checkmark__outer  {

  & .checkmark{
    background-color: $grey;
  }


}

/* When the checkbox is checked, add a blue background */
.checkbox_container input:checked ~ .checkmark__outer {
  & .checkmark{
    background-color: $orange;
  }
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark__outer:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
/*
.checkbox_container input:checked ~ .checkmark__outer:after {
  display: block;
}
*/
/* Style the checkmark/indicator */
.checkbox_container .checkmark__outer:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid $grey_light;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}


.contact-info {
  @extend %clearfix;
  margin-bottom: 56px;
  font-family: Arial;
  font-size:0.9375rem ;
  color: $grey_dark;

}

.contact-info__left {
  float: left;
  width: 31%;
  text-align: left;
  @include phone{
    display: block;
    width: 100%;
    margin-bottom: 57px;
    padding-left: 0px;
  }


}
.contact-info__right {
  float: left;
  width: 69%;
  padding-left: 33px;
  border-left: solid 5px $orange;

  @include phone{
    display: block;
    width: 100%;
  }
}
.contact-info {
  font-family: Arial;
  font-size: 0.9375rem;
  .contact-info__data > div {
    padding-left: 0px;
    font-size: 0.9375rem;
  }

  .contacts__phone-text {
    padding-left: 0px;
  }
  .contacts__name {
    font-family: 'Arial', sans-serif;
    font-size: 0.875rem;
    color:$grey;
    line-height: 1.42;
    padding-left: 45px;
    margin-bottom: 19px;
  }
}
.contact-info__line {
  margin-bottom: 78px;
}
.line-grey {

  border-bottom: solid 2px $grey;
}

.contact-form__time{
  text-align: left;
  padding-left: 20px;
}



