.pages-news {
  @extend %clearfix;
  position: relative;
}

.news_list, .new {
  float: left;
  width: 74%;
  margin-bottom: 40px;
  @include phone {
    display: block;
    width: 95%;
    margin: 0 auto;
    margin-top: 20px;

  }

}
.news_rbar {
  float: left;
  width: 23%;
  margin-left: 27px;
  @include phone {
    display: block;
    width: 100%;
    margin-left: 0px;


  }
}
.news_list__items {



}
.news_list__item {
  display: inline-block;
  font-size: 0.875rem;
  color: $grey_dark;

  text-align: left;
  margin-bottom: 53px;
  width: 100%;
}
.news_list__item-date {
  color:$grey;
  margin-bottom: 10px;

}
.news_list__item-title {
  font-size: 1.325rem;
  font-family: din_bold;
  font-weight: 600;
  color: $grey_dark;
  margin-bottom: 28px;
}

.news_list__item-desc {
  font-size: 0.875rem;
  color: $grey_dark;
  margin-bottom: 32px;
  max-height: 125px;
  overflow: hidden;
  text-overflow: ellipsis; /* Многоточие */

}


h2.news_list__item-line {
 /* height: 60px;*/
  width: 100%;
  text-align: right;
  line-height: 0.4;
  border-bottom: dashed $orange 1px;
  position: relative;
  padding-left: 46px;
}
.new__social-network-wrapp {
  width: 26px;
  height: 26px;
  background-color: $white;
  position: absolute;
  top:-4px;
  left:0px;
}
.new .social-network {

  width: 26px;
  height: 26px;
  fill:$orange;


}
.news_list__item-comment  {
  font-size: 0.875rem;
  color: $grey;
  background-color:$white;
  padding-left: 28px;
  &:hover {
    color: $grey_dark;
    cursor: pointer;
  }

}

.news_list__show-all {
  font-size: 1.125rem;
  color: $orange;
  margin-bottom: 40px;
  text-align: center;
  &:hover {
    color: $orange_hover;
    cursor: pointer;
  }

}
/*right bar*/
.filter {
  width: 100%;
  border-top: dashed $orange 1px;
  border-bottom: dashed $orange 1px;
  font-family: din_light;
  font-size: 1.5rem;
  text-transform: uppercase;
  margin-bottom: 40px;
  text-align: center;
  margin-left: 0px;
}
.filter__all {
 color: $orange;
 margin: 20px auto;
  @include phone(){
    display: inline-block;
  }
}
.filter__yeas {
  color: $grey_dark;
  margin-bottom: 20px;
}


/**/
.news_rbar.filter {
  display: block;
  @include phone(){
    display: none;
  }

}

.news_list.filter {
  display: none;
  width: 100%;
  border: none;
  text-align: left;
  @include phone(){
    display: block;
  }
}
.filter__years__items {
  display: inline-block;
  li {
    display: inline;
    margin-left: 60px;
  }
}


/*single new*/
.new_text {
  font-size: 0.875rem;
  color: $grey_dark;
  margin-bottom: 32px;

}
.new {
  min-height: 100%;
}
.new__navigator {
  position: absolute;
  bottom: 0px;
  left: 37%;
  margin-left:-142px ;
 /* width: 300px;*/


}
.navigator {
  .arrow-right1 {
    display: inline-block;
    vertical-align: middle;
    width: 31px;
    height: 31px;
    fill: $orange;
    &:hover {
      fill: $orange_hover;
      cursor: pointer;
    }
    margin-left: 6px;
  }
  .arrow-left1 {

    display: inline-block;
    vertical-align: middle;
    width: 31px;
    height: 31px;

    fill: $grey_dark;
    &:hover {
      fill: $grey_hover;
      cursor: pointer;
    }
    transform: rotate(180deg);
  }
}
.new__navigator__pred {

  padding: 6.5px 0px;
  vertical-align: middle;
  color: $grey;
  font-size: 1.125rem;

  &:hover {
    fill: $grey_hover;
    cursor: pointer;
  }

}
.new__navigator__next {
  display: inline-block;
  padding: 6.5px 0px;
  vertical-align: middle;
  color: $orange;
  font-size: 1.125rem;
  &:hover {
    fill: $orange_hover;
    cursor: pointer;
  }
}


.banners-items{
  @include phone(){
    width: 255px;
    margin: 0 auto;
  }
}