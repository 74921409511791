.accordeon {
  width: 400px;
  margin: 0 auto;



}
.accordeon_list {

  padding: 0;
  margin: 0;
  list-style: none;


}
.accordeon__trigger {
  display: inline-block;
  padding: 15px 0px;

}

.accordeon__sublist {
  display: none;
  font-size: 14px;
  padding-left: 15px;
}
.accordeon__sublist-item {

}

.accordeon__item {
 text-align: left;
  border-bottom: 2px solid green;
  &.active + .accordeon__sublist{
    display: block;

  }
}

