.slider {
  overflow: hidden;
  display: inline-block;
  position: relative;


}
.slider__items {
  margin-bottom: 30px;
}
.slider__item {
  position: absolute;
  top:0;
  left:0;
  width: 100%;
  z-index: 0;
  img {
    width: 100%;
    display: block;
  }
  &.active {
    z-index: 1;
    position: relative;
  }
  &.inslide {
    z-index: 2;
  }

}




.slider__controls {
  position: relative;
  margin-bottom: 84px;
  height: 60px;

}
.line_orange {
  height: 5px;
  width: 45%;
  border-top: solid #e64814 5px;
  position: absolute;
  top: 50%;
  margin-top: -15px;
  left: 0px;


}
.line_orange_right {
  left: 55%;

}
.arrows {
  width: 66px;
  height: 60px;
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -33px;
  background-color: $white;
  z-index: 3;
}

.slider__controls-button {



  &.slider__controls-button_next {

  }
  &.slider__controls-button_prev {



  }

}


.slider_dots {
  padding: 0px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0px);
  bottom: 5%;
  z-index:5;

}
.slider__dots-item {
  display: inline-block;
  .slider__dots-link {
    display: block;
    width: 14px;
    height: 14px;
    background: black;
    border-radius: 50%;
    border: solid 2px #CCCCCC;

  }
  &.active {
    .slider__dots-link {
      background-color: #c4c4c4;
    }
  }
}