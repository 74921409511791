.search {
  font-size: 0.875rem;
  display: inline-block;

 // padding: 17px 0px;
  width: 100%;
  margin-bottom: 46px;
 /* @include tablets {
    width: 70%;
  }*/
  @include phone{
    width: 100%;

  }
}
.search-input-wrap {


}
.search-btn__wrapper {
  display: inline-block;
  position: absolute;
  vertical-align: middle;
  width: 45px;
  height: 45px;
  background: $orange;
  margin-left: 10px;
  &:hover {
    background: $orange_hover;
  }
}
.search-btn{
  position: absolute;
  width: 26px;
  height: 26px;
  top: 50%;
  left: 50%;
  margin-top: -13px;
  margin-left: -13px;



}

.search-input {
  display: inline-block;
  font-size: 0.9375rem;
  color: $grey;
  border:none;
  line-height: 1.4;
  outline: none;
  padding: 12px 0px 12px 17px;
  width: 88%;
  @include phone{
    width: 88%;

  }
  margin-bottom: 52px;

}
.search__label {
  color: $white;
  font-family: din_bold;
  font-size: 1.25rem;
  margin-bottom: 17px;

}

.pages_header__search {

  .search__label {
    color: $grey_dark;
    font-size: 1.25rem;

  }
  .search-input {
    color: $grey_dark;
    background-color: $grey_light;
    border: solid #e0dfdd 1px;
  }

}