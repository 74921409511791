.pages-promo {
  position: relative;
}
.pages-news__title {
  width: 100%;
  padding-top: 40px;
  background-color: $grey_light ;
  margin-bottom: 45px;
}

.promos__items {

}
.promos__item {
  display: inline-block;
  font-size: 0.875rem;
  color: $grey_dark;

  text-align: left;
  margin-bottom: 53px;
  width: 100%;
}
.promos__item-date {
  color:$grey;
  margin-bottom: 10px;

}
.promos__item-title {
  font-size: 1.325rem;
  font-family: din_bold;
  font-weight: 600;
  color: $grey_dark;
  margin-bottom: 28px;
}
.promos__item-content {
  font-size: 0px;
  margin-bottom: 32px;

}

.promos__item-photo {
  display: inline-block;
  width: 255px;
  height: 135px;
  img {
    width: 100%;
  }
  @include phone {
    width: 100%;
  }
}
.promos__item-desc {
  display: inline-block;
  font-size: 0.875rem;
  width: 73%;
  color: $grey_dark;

  margin-left: 32px;
  max-height: 125px;
  overflow: hidden;
  text-overflow: ellipsis; /* Многоточие */
  vertical-align: top;
  @include phone {
    margin-left: 0px;
    width: 100%;
  }
}

h2.promos__item-line {
  /* height: 60px;*/
  width: 100%;
  text-align: right;
  line-height: 0.4;
  border-bottom: dashed $orange 1px;
  position: relative;
}
.new__social-network-wrapp {
  width: 46px;
  height: 26px;
  background-color: $white;
  padding-right: 20px;
  position: absolute;
  top:-4px;
  left:0px;
}
.new .social-network {

  width: 26px;
  height: 26px;
  fill:$orange;


}
.promos__item-comment  {
  font-size: 0.875rem;
  color: $orange;
  background-color:$white;
  padding-left: 28px;
  &:hover {
    color: $orange_hover;
    cursor: pointer;
  }

}

.promos__show-all {
  font-size: 1.125rem;
  color: $orange;
  margin-bottom: 40px;
  text-align: center;
  &:hover {
    color: $orange_hover;
    cursor: pointer;

  }
}

/* promo*/
.promo{
  margin-bottom: 76px;
}
.promo_content {
  display: table;
  margin-bottom: 40px;
  @include phone {
    display: block;
    width: 100%;
  }
}
.promo__photo-row{
  display: table-row;
  @include phone {
    display: block;
    width: 100%;
  }

}
.promo__photo {
 display: table-cell;
  width: 544px;
  text-align: center;
  vertical-align: middle;
   img {
    width: 100%;
    display: inline-block;
   vertical-align: middle;
    @include phone {
       display: block;
       width: 100%;
     }
  }
}
.promo__desc-wrap {
  display: table-cell;
  vertical-align: top;

  background-color: $orange;
  color: $grey_light;
  text-transform: uppercase;
  padding-top: 25px;
  padding-left: 40px;

  @include phone {
    display: block;
    width: 100%;
    height: min-content;
    margin-bottom: 40px;
  }

}
.promo__desc_1 {
  font-size: 2.25rem;
  font-family: din_bold;
  margin-bottom: 25px;
  line-height: 1;

}
.promo__desc_2{
  font-size: 1.5rem;
  font-family: din_light;
  margin-bottom: 25px;
  line-height: 1;
}

.promo__text2 {
  color: $grey_dark;
  font-size: 0.875rem;
  line-height: 2;

}

.pages-promo__form {
  margin-bottom: 35px;
}
.promo__navigator {
  width: 286px;
 margin: 0 auto;
}

/*
*/
.pages-promo__redline {
  margin-bottom: 36px;
}






