body {
  overflow: auto;
}
.pages__title {
  width: 100%;
  padding-top: 20px;
  background-color: $grey_light ;
  margin-bottom: 45px;
}
.title {
  font-size: 2.25rem;
  font-family: din_bold;
  font-weight: 600;
  color: $grey_dark;
  text-transform: uppercase;
  margin-bottom: 15px;
}
.breadcrumbs {
  display: inline-block;
  font-family: Arial;
  font-style: italic;
  font-size: 0.8125rem;
  margin-bottom: 21px;

}

.list-item-date {
  color:$grey;
  margin-bottom: 10px;

}
.list-item-title {
  font-size: 1.325rem;
  font-family: din_bold;
  font-weight: 600;
  color: $grey_dark;
  margin-bottom: 28px;
}

.list-item-desc {
  font-size: 0.875rem;
  color: $grey_dark;
  margin-bottom: 32px;
  max-height: 125px;
  overflow: hidden;
  text-overflow: ellipsis; /* Многоточие */

}

.arrow-right-orange {
  width: 31px;
  height: 31px;
  fill:$orange;
  &:hover {
    fill: $orange_hover;
    cursor: pointer;
  }
}
.arrow-left-grey {
  width: 31px;
  height: 31px;
  fill: $grey_dark;
  &:hover {
    fill: $grey_hover;
    cursor: pointer;
  }
  transform: rotate(180deg);
}

.count-form__btn-wrapper {
  width: 224px;
  display: block;
  margin: 27px auto;


}
.button_orange {
  display: inline-block;
  background-color: $orange;
  font-family: din_bold;
  color: $grey_light;
  font-size: 1.125rem;
  text-transform: uppercase;
  padding: 20px 30px;

  &:hover {
    background-color: $orange_hover;
    cursor: pointer;
  }

}
.button_orange_wd {
  width: 100%;
  text-align: center;
}


/*red line*/

.red_line-wrapp {
  position: relative;
  width: 100%;
  height: 26px;
}
.red_line-margin {
  margin-top: 30px;
}
.red_line {
  width: 90%;
  border-bottom: dashed #e64814 1px;
  height: 1px;
  position: absolute;
  top: 13%;
  left: 46px;

}

.red_line_total {
  width: 100%;
  border-bottom: dashed #e64814 1px;
  height: 1px;


}

.social-network-wrapp {

}
.red_line__social-network {
  position: absolute;
  top: 0px;
  left: 0px;
  margin-top: -13px;
}
.social-network {

  width: 26px;
  height: 26px;
  fill:$orange;

}

.red_line__comment  {
  position: absolute;
  top: 0px;
  right: 0px;
  margin-top: -9px;

  font-size: 0.875rem;
  color: $grey;
  background-color:$white;
  padding-left: 28px;
  &:hover {
    color: $grey_dark;
    cursor: pointer;
  }
}


