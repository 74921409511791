.menu-search {
  display: block;
  font-size: 1rem;
}
.menu {
  font-size: 1rem;
  font-family: din_bold;
  color: $grey_light;
  background: $grey_dark;
  text-align: left;
  padding-top: 14px;

}
.menu__items {
  text-transform:uppercase;
}
.menu__item {

  margin-bottom: 30px;
   &:first-child {
    margin-top: 14px;
  }
  &:last-child {
    margin-bottom: 0px;
  }
}
.menu__item_red {
  padding: 17px 0px;
  padding-left: 13px;
  text-transform:none;
  font-size: 0.94rem;
  font-family: Arial;
  background: $orange;
  margin-bottom: 0px;


}
.menu__link {
  font-size: 1rem;
  font-family: din_bold;
  color: $grey_light;
  padding: 4px 0px;
  padding-left: 26px ;
  &:hover{
    color: $orange;
    cursor: pointer;
    border-left: solid $orange 8px;
    padding-left:18px;
  }
}
.menu__link {
  &.active {
    color: $orange;
  }


}

.menu__link_red {
  position: relative;
  padding-left: 26px ;
  &::before {
    content: " ";
    background: url("../img/icons/arrow-ffffff.svg") no-repeat center center ;
    height: 20px;
    width: 15px;
    display: block;
    position: absolute;
    top: 50%;
    margin-top: -10px;
    left: 0px;

  }

 // padding: 17px;
  &:hover{
   color: white;
   cursor: pointer;
   border-left: none;


  }

}