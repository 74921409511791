.filter-box-overlay {
  display: none;
  position: fixed;
  overflow: auto;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  z-index: 2;
}


.filter-box {
  width: auto;
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  box-shadow: 0 2px 6px #010101; /* Параметры тени */
  padding: 27px 32px 16px 32px;
  z-index: 3;
}
.filter-box__name {
  display: inline-block;
  font-family:din_light;
  font-size:1.5rem;
  color: $grey_dark;
  margin-bottom:34px;
  margin-right:60px;

}

.filter-box__items {
 columns:4;
  @include phone {
    columns:1;
  }
  margin-bottom:30px;
}
filter-box__item {
  display: inline-block;

}
.filter__checkbox {
  display: inline-block;

}
.filter__name {

  display: inline-block;
  font-size:0.9375rem;
  color: $grey_dark;

}
.button_orange-wrapp {

  width: 100%;
  text-align: center;
}

.filter-box__clear {
  display: block;
  color: $grey;
  font-size:0.875rem;

}
.button_orange_wd_218 {
  width: 218px;
  display: block;
  margin-bottom:15px;
}
.filter-box__close {

  &:hover {
    cursor: pointer;
    .close-icon {
      fill: $grey_dark_hover;
    }
  }
}

.close-icon {
  width: 29px;
  height: 29px;
  fill: $grey_dark;
  position: absolute;
  top:18px;
  right: 18px;
}