@font-face {
    font-family: 'din_bold';
    src: url('../fonts/DINPro-web/DINPro-Bold.eot');
    src: url('../fonts/DINPro-web/DINPro-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
     url('../fonts/DIN2014/DIN2014-Bold.otf') format('opentype');

    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'din_regular';
    src: url('../fonts/DIN2014/DIN2014-Regular.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'din_light';
    src: url('../fonts/DIN2014/DIN2014-Light.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

@mixin din($style: regular) {
    @if $style == regular {
        font-family: 'din_regular';
    }

    @if $style == bold {
        font-family: 'fira_sansbold';
    }
}

@font-face {
    font-family: 'FontAwesome';
    src: url('../fonts/FontAwesome/fontawesome-webfont.ttf') format('truetype'),
    url('../fonts/FontAwesome/FontAwesome.otf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
