/*.hamburger
{
  display: block;


  //background-image:  url("../img/icons/svg/hamburger.svg");


}
/*
.hamhamburger__img{
  width: 40px;
  height: 35px;
}
*/
.hamburger-menu__label {
  font-family: din_bold;
  font-size: 1.25rem;
  color: $grey_light;
  display: inline-block;
  position: absolute;
  right: 52px;

}
.hamburger-menu__label_dark {
  color: $grey;
}
.hamburger-menu{
  display: inline-block;
   width: 30px;
   height: 30px;
   position: absolute;
   top: 0px;
   right: 0px;





&__link{
     opacity: .6;
     font-size: 46px;
     position: relative;
     display: block;
     height: 100%;
     z-index: 50;
     transition: $transition;
&:hover{
     opacity: 1;
 }

&::before{
     content: "";
     display: block;
     position: absolute;
     top: 0;
     height: 3px;
     width: 100%;
     background-color: $grey_light;
     border-radius: 10px;
     transform-origin: center center;
     transition: $transition;
 }

&::after{
     content: "";
     display: block;
     position: absolute;
     bottom: 0;
     height: 3px;
     width: 100%;
     background-color: $grey_light;
     border-radius: 10px;
     transform-origin: center center;
     transition: $transition;
 }
}
}

.hamburger-menu__link.active{

&::before{
     transform: translateY(-50%) rotate(-45deg);
     top: 50%;
 }

&::after{
     transform: translateY(50%) rotate(45deg);
     bottom: 50%;
 }

.hamburger-menu__link-center{
    opacity: 0;
}
}

.hamburger-menu__link-center{
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 3px;
    width: 100%;
    background-color: $grey_light;
    border-radius: 10px;
    transform-origin: center center;
    transition: $transition;
}

.hamburger-menu.hamburger-menu_dark {
  .hamburger-menu__link {
    &::before {
      background-color: $grey;
    }
    &::after {
      background-color: $grey;
    }
  }
  .hamburger-menu__link-center{
    background-color: $grey;
  }

}