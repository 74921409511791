.header {
  position: relative;
  color: $white;
  font-size: 0.75rem;
  width: 100%;

}

.header_top {
  margin-bottom: 250px;
  @include phone {
    margin-bottom: 60px;
    position: relative;
    height: 300px;
    text-align: center;

  }
}
.header__logo {
  padding-top: 50px;
  @include phone {
    margin-top: 50px;
    display: inline-block;
    vertical-align: middle;
    width: 124px;
    height: 155px;
    left: 50%;
    top: 50%;
  }
}
.logo__text {

}
.header__menu-wrap{
  position: relative;
  display: block;
  text-align: right;
  top:20px;

}
.header__menu {
  width: 238px;
  position: absolute;
  top:30px;
  right:0px;
  display: none;
  @include desktop
  {
    right: 20px;
  }
   @include phone{
    width: 80%;
    height: 100%;
    z-index: 2;

  }
}
.header__search {

  width: 48%;
  @include phone{
    width: 85%;
  }

}

.header_catalog-wrap {
  padding-bottom:50px ;
  box-sizing: content-box;
}

.header_catalog {

  border-left: $orange solid 6px ;
  width: 313px;
  font-family: din_bold;
  font-size: 36px;
  padding-left: 37px;


}
.header_catalog__text {
  position: relative;
  line-height: 1.2;
  display: inline-block;
  vertical-align: middle;

}
.arrow-right_white {
  position: absolute;
  vertical-align: middle;
  width: 36px;
  height: 36px;
  right: -50px;
  bottom: 5px;
  fill: $white;
}


//header::before {
.header__bg {

  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: -1;
  transform-origin: center;

  min-height: 100vh;
  background-image : url("../img/decor/earth-pulsar.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  transition: 1s;
  /* Параллакс */


  transform: translateZ(-1px) scale(2);
  -moz-transform: translateZ(-1px) scale(2);
  -ms-transform: translateZ(-1px) scale(2);
}

/**/
.pages_header {
  @extend %clearfix;
}
.pages_header__bottom{
  width: 100%;
  text-align: center;
  @include phone(){
    width: 427px;
  }
}
.pages_header__link {
  float: left;
  width: 120px;
  @include phone(){
    display:inline-block;
    margin: 0 40%;


  }


}

.pages_header__logo {

}
.header__search {
  margin-left: 46px;

}
.pages_header__search {
  text-align: left;
  float: left;
  margin-left:140px;
  margin-top: 35px;
  width: 57%;
  @include phone(){
    display: block;
    width: 100%;
    margin-left:0px;


  }


}
.pages_header__logo img {
  width: 117px;
  height: 152px;
}
.pages_header__top {
  height: 50px;
  position: relative;
}
.header__menu-wrap2 {
  position: relative;
  display: block;
  text-align: right;
  top:14px;
}
.make-order-label {
  color: $grey_dark;
  font-size: 0.9375rem;
  position: absolute;
  right: 190px;
  top: 50%;
  margin-top: -7.5px;
  &:hover {
    color: $grey_dark_hover;
    cursor: pointer;
  }

}
.basket {
  fill: $grey_dark;
  width: 33px;
  height: 33px;
  position: absolute;
  right: 140px;
  top: 50%;
  margin-top: -16.5px;
  &:hover {
    color: $grey_dark_hover;
    cursor: pointer;
  }



}