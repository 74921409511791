.news {
  width: 100%;
  margin-bottom: 60px;
  padding-top: 96px;
  border-bottom: solid $grey_light 6px;
  background-color: $white;
}
.news__title {
  display: inline-block;
  font-size: 2.25rem;
  font-family: din_bold;
  font-weight: 600;
  color: $grey_dark;
  text-transform: uppercase;

  &.hover {
    text-decoration: underline;
  }
}
.news__titles {
  margin-bottom: 50px;

}
.news__titles2 {
  position: relative;
}
.news__navigator {
  display: inline-block;
  height: 100%;
}
.arrow-right {
  position: absolute;
  vertical-align: middle;
  width: 30px;
  height: 30px;
  top: 50%;
  margin-top: -15px;
  right: 0px;
  fill:$orange;
  &:hover {
    fill: $orange_hover;
    cursor: pointer;
  }
}
.arrow-left {
  position: absolute;
  vertical-align: middle;
  width: 31px;
  height: 31px;
  top: 50%;
  margin-top: -15px;
  right: 2rem;
  fill: $grey_dark;
  &:hover {
    fill: $grey_hover;
    cursor: pointer;
  }
  transform: rotate(180deg);
}

.news__forward {
  position: absolute;
  vertical-align: middle;
  width: 17px;
  height: 32px;
  top: 10px;
  right: 38px;
}

.news__items {
  font-size: 0px;
  margin-bottom: 35px;
}
.news__item {

  display: inline-block;
  font-size: 0.875rem;
  color: $grey_dark;
  padding-bottom: 3.5rem;
  border-bottom: dashed $orange 1px;
  text-align: left;

 @include phone_only {
   width: 95%;
   margin: 0 auto;
   margin-top: 20px;

  }
  @include tablets_only {

    width: 45%;
    margin-left: 1.875rem;
    &:nth-child(2n+1){
      margin-left: 0px;
    }
  }
  @include laptop_only {
    margin-left: 1.875rem;
    width: 30%;
    &:nth-child(3n+1){
      margin-left: 0px;
    }
  }
  @include desktop_only {
    margin-left: 1.875rem;
    width: 22%;
    &:nth-child(4n+1){
      margin-left: 0px;
    }
  }

}
.news__item-date {
  color:$grey;
  margin-bottom: 10px;

}

news__item-desc{

}
.news__show-all {

  font-size: 1.125rem;
  color: $orange;
  margin-bottom: 40px;
  text-align: center;
  &:hover {
    color: $orange_hover;
    cursor: pointer;
  }

}